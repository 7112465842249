export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function parseMonthYear(monthYear) {
  const [year, month] = monthYear.split("-");
  const monthName = months[parseInt(month, 10) - 1];
  return `${monthName}, ${year}`;
}

export function formatDateString(date) {
  const day = date.day;
  const monthName = months[date.month - 1];
  const year = date.year;

  return `${monthName} ${day}, ${year}`;
}
